body {
  background-color: black;
  color: white;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  letter-spacing: 0.2px;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  color: inherit;
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3 {
  font-weight: 300;
}

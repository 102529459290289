#intro {
  @include modal;
  z-index: 3;

  .container {
    max-width: 360px;
  }

  h1 {
    margin: 0;
    background: url('../../assets/intro-jewel.svg') no-repeat center 25px;
    background-size: 190px auto;
    text-transform: uppercase;
    font-size: 18px;

    span {
      display: block;

      &:nth-child(2) {
        margin-top: 42px;
        margin-bottom: 96px;
      }
    }
  }

  h2 {
    margin: 50px 0;
    font-size: 14px;
    line-height: 22px;
  }

  button {
    @include button-underline;
  }
}

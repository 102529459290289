#nav {
  position: fixed;
  z-index: 7;
  top: 5vh;
  right: responsive(30, 80);

  button {
    @include button-underline;
  }
}

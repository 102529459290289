@import 'variables';
@import 'bootstrap';

@import 'nav';
@import 'intro';
@import 'tutorial';
@import 'info';
@import 'info-jewel';

@import 'overlays';
@import 'horizontal';
@import 'vertical';
@import 'controls';

#container {
  position: fixed;
  top: 0;
  left: 0;
}

#picture-image {
  position: fixed;
  top: -9999px;
  left: -9999px;
}

#info {
  @include modal;
  z-index: 5;
  visibility: hidden;
  opacity: 0;

  .container {
    padding-top: responsive(60, 100);
    max-width: 590px;
  }

  .text {
    p {
      margin: 30px 0;
      font-size: 14px;
      line-height: 22px;
    }

    .jewel {
      width: 45px;
      height: auto;
    }
  }

  .links {
    margin-top: 60px;

    p {
      margin: 40px 0;
    }

    a {
      @include button-underline;
    }
  }

  .credits {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    color: $mid-grey;
    margin-top: 80px;

    a {
      @include hover-underline;
      transition: 0.3s color;
      font-weight: bold;

      &:after {
        width: calc(100% + 0px);
        margin-top: 2px;
      }

      &:hover {
        color: white;
      }
    }
  }
}
